import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import $ from 'jquery';
import {findDOMNode } from 'react-dom'
import { signout, isAuthenticated } from '../../api/auth'
import Sidebar from './Sidebar'

const navigationmenu = [
  {
      id: 1,
      link: '/',
      linkText: 'Home',
      child: false,
  },
  {
    id: 2,
    linkText: 'Account',
    link: '/signin',
    child: true,
    submenu:[
      {
        id: 19,
        link:'/admin/dashboard',
        linkText:'Admin Dashboard',
      },
      {
        id: 20,
        link:'/user/dashboard',
        linkText:'Member Dashboard',
      },
      {
        id: 21,
        link:'/signin',
        linkText:'Sign In',
      },
      {
        id: 22,
        link:'/signup',
        linkText:'Sign Up',
      },
      {
        id: 23,
        link:'/',
        linkText:'Sign Out',
      },
    ]
  },
  {
    
    id: 22,
    linkText: 'Shop',
    link:'/shop',
    child: false,
    
},
  {
    
    id: 3,
    linkText: 'Gallery',
    link:'/gallery',
    child: false,
    
},
{
  id: 4,
  linkText: 'About',
  link:'/about',
  child: false,
  // submenu:[
  //   {
  //     id: 31,
  //     link:'/news',
  //     linkText:'News',
  //   },
  //   {
  //     id: 32,
  //     link:'/news-details',
  //     linkText:'News Details',
  //   },
  // ]
  
},
  {
    id: 5,
    link: '/contact',
    linkText: 'Contact',
    child: false,
  },
  {
    id: 6,
    link: '/faq',
    linkText: 'FAQ',
    child: false,
  },

]


class Header extends Component {

constructor(props) {
  super(props);
  this.state = {
     redText: false
  };
  this.addClass = this.addClass.bind(this);
  this.removeClass = this.removeClass.bind(this);
  this.removeAll = this.removeAll.bind(this);
}
addClass() {
  this.setState({
    redText:true
  });
}

removeClass() {
  this.setState({
    redText:false
  });
}
removeAll() {
  this.setState({
    redText:false
  });
}
componentDidMount() {
  window.addEventListener('resize', () => {
      this.setState({
          isMobile: window.innerWidth < 1020
      });
  }, false);
  window.addEventListener('load', () => {
    this.setState({
        isMobile: window.innerWidth < 1020
    });
}, false);

  window.addEventListener('scroll', () => {
    this.setState({
        isTop: window.scrollY > 150
    });
}, false);
}
navToggle = () => {
  const nv = findDOMNode(this.refs.navmenu);
  const nvb = findDOMNode(this.refs.navbtn);
  $(nv).toggleClass('menu-on');
  $(nvb).toggleClass('active');
}
removenavToggle = () => {
  const nv = findDOMNode(this.refs.navmenu);
  const nvb = findDOMNode(this.refs.navbtn);
  $(nv).removeClass('menu-on');
  $(nvb).removeClass('active');
}

getNextSibling = function (elem, selector) {

	// Get the next sibling element
	var sibling = elem.nextElementSibling;

	// If there's no selector, return the first sibling
	if (!selector) return sibling;

	// If the sibling matches our selector, use it
	// If not, jump to the next sibling and continue the loop
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.nextElementSibling
	}

}

triggerChild = (e) => {
  let subMenu = '';

  subMenu = ( this.getNextSibling(e.target, '.submenu') !== undefined ) ? this.getNextSibling(e.target, '.submenu') : null;

  if(subMenu !== null && subMenu !== undefined && subMenu !== ''){
    subMenu.classList = subMenu.classList.contains('open') ? 'submenu' : 'submenu open';
  }
}


signoutOfAccount = history => e => {
  e.preventDefault()
  signout(() => history.push("/"))
}


  render() {
    const className = this.state.isMobile ? 'breakpoint-on' : '';
    const classNamess = this.state.isMobile ? 'd-none' : '';
    const classNamesss = this.state.isTop ? 'sticky-active' : '';
    const { history } = this.props;
    return (
      <div>
        {/*====== HEADER START ======*/}
        <header className={`header-absolute sticky-header ${classNamesss}`}>
          <div className="container container-custom-one">
            <div className={`nav-container d-flex align-items-center justify-content-between ${className}`}>
              {/* Main Menu */}
              <div className="nav-menu d-lg-flex align-items-center" ref="navmenu">
                {/* Navbar Close Icon */}
                <div className="navbar-close" onClick={this.removenavToggle}>
                  <div className="cross-wrap"><span className="top" /><span className="bottom" /></div>
                </div>
                {/* Off canvas Menu  */}

                <div className="toggle" onClick={this.addClass}>
                  <Link to="#" id="offCanvasBtn"><i className="fal fa-bars" /></Link>
                </div>
                {/* Mneu Items */}
                <div className="menu-items">

                  <ul>
                  {navigationmenu.length > 0 ? 
                  navigationmenu.map((item, i) => (
                      <li key={i} className={` ${item.child ? 'menu-item-has-children' : ''} `} onClick={this.triggerChild}>
                      {item.child ? <Link onClick={e => e.preventDefault()} to="/"> {item.linkText} </Link> : <Link to={item.link}> {item.linkText} </Link> }
                          {item.child ?
                              <ul className="submenu" role="menu">
                                  {item.submenu.map((sub_item, i) => {
                                    if(sub_item.id === 19){
                                      if(isAuthenticated() && isAuthenticated().user.role === 1) { 
                                      return <Link to="/admin/dashboard">Admin Dashboard</Link>
                                    } 
                                      else return null
                                       
                                    }
                                    if(sub_item.id === 20){
                                      if(isAuthenticated() && isAuthenticated().user.role === 0) { 
                                      return <Link to="/user/dashboard">Member Dashboard</Link>
                                    } 
                                      else return null
                                    }
                                    if(sub_item.id === 21){
                                      
                                      if(isAuthenticated()) { 
                                        return null
                                    } 
                                      else return <Link to="/signin">Sign In</Link>
                                    }
                                    if(sub_item.id === 22){
                                      
                                      if(isAuthenticated()) { 
                                        return null
                                    } 
                                      else return <Link to="/signup">Sign Up</Link>
                                    }
                                    if(sub_item.id === 23){
                                      if(isAuthenticated()) {
                                        return <Link onClick={() => this.signoutOfAccount(history)}>
                                        Signout
                                        </Link>
                                      }
                                      else {
                                        return null
                                      }
                                    }

                                    return (
                                      <li key={i} className={`${sub_item.child ? 'menu-item-has-child' : ''} `}>
                                      {sub_item.child ? 
                                      <Link onClick={e => e.preventDefault()} to="/"> {sub_item.linkText} </Link> : 
                                      <Link to={sub_item.link}> {sub_item.linkText} </Link> }
                                          {sub_item.third_menu ?
                                              <ul className="submenu">
                                                  {sub_item.third_menu.map((third_item, i) => (
                                                      <li key={i}><Link
                                                          to={third_item.link}>{third_item.linkText}</Link>
                                                      </li>
                                                  ))}
                                              </ul> : null}
                                      </li>
                                      )
                                    })}
                              </ul>
                              : null
                          }
                      </li>
                  )) : null}
                  </ul>

                </div>
                {/* from pushed-item */}
                <div className="nav-pushed-item" />
              </div>
              {/* Site Logo */}
              <div className="site-logo" style={{ textAlign: 'center'}}>
                <Link to="/"><img src="assets/img/gklogo/logo.png" alt="" /><h1>G&K</h1>
                <p style={{ color: ''}}>&nbsp;Apparel Prints</p></Link>
              </div>
              {/* Header Info Pussed To Menu Wrap */}
              <div className={`nav-push-item ${classNamess}`}>
                {/* Header Info */}
                <div className="header-info d-lg-flex align-items-center">
                  <div className="item">
                    <i className="fal fa-phone" />
                    <span>Phone Number</span>
                    <Link to="tel:+3104618847">
                      <h5 className="title">+424 509-5010</h5>
                    </Link>
                  </div>
                  <div className="item">
                    <i className="fal fa-envelope" />
                    <span>Email Address</span>
                    <Link to="mailto:info@webmail.com">
                      <h6 className="title">gkapparelprints@gmail.com</h6>
                    </Link>
                  </div>
                </div>
              </div>
              {/* Navbar Toggler */}
              <div className="navbar-toggler" onClick={this.navToggle}  ref="navbtn">
                <span /><span /><span />
              </div>
            </div>
          </div>
        </header>
        {/*====== HEADER PART END ======*/}
        {/*====== OFF CANVAS START ======*/}
        <Sidebar 
          classNames={classNames}
          Link={Link}
          isAuthenticated={isAuthenticated}
          signout={signout}
          redText={this.state.redText}
          removeClass={this.removeClass}
          removeAll={this.removeAll}
          history={this.props.history}
        />
      
        {/*====== OFF CANVAS END ======*/}
      </div>
    );
  }
}

export default withRouter(Header);
